<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card>
      <v-card-title
        class="text-h5 grey--text text--darken-2 yellow lighten-3 pa-4"
      >
        Event Details
      </v-card-title>

      <v-card-text class="pa-4 text-h6">
        Sorry! Your access has been expired.
        <br />
        Please book for your class with tutor. <br />
        Or contact to administrator +88 09638 011 099.<br />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
  <script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
  
  <style scoped>
/* Add styles as needed */
</style>
  